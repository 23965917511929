// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------

import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isVisible } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $('.dynamic p > a.button, .dynamic p > a.button--secondary').each(function() {
    $(this).parent().addClass('buttonMargin')
  })
  $('.dynamic p.buttonMargin').each(function() {
    if(!($(this).prev().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--first')
    }
    if(!($(this).next().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--last')
    }
  })
}

// Ajouter un <span> dans les boutons pour l'animation
export function addSpanToButtons() {
  let buttons = $('.button, .button--secondary, .js-hover-btn')
  buttons.wrapInner('<span></span>')
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
  let themePath = window.config.theme_path

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-external')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-download')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

}

export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


export function changeBackgroundColorOnScroll() {
  let page = document.querySelector('.page')

  
  function onScroll() {

    if(window.innerWidth > 800) {
      if(window.scrollY > 600) {
        page.classList.add('backgroundChange')
      } else {
        page.classList.remove('backgroundChange')
      }
    } else if(window.innerWidth > 500 && window.innerWidth < 800) {
      if(window.scrollY > 800) {
        page.classList.add('backgroundChange')
      } else {
        page.classList.remove('backgroundChange')
      }
    } else if(window.innerWidth > 300 && window.innerWidth < 500) {
      if(window.scrollY > 400) {
        page.classList.add('backgroundChange')
      } else {
        page.classList.remove('backgroundChange')
      }
    }
  }

  OBSERVER.add({
    name: 'changeBackgroundColor',
    event: 'scroll',
    target: 'window',
    function: onScroll
  })

  onScroll()

  OBSERVER.on('changeBackgroundColor')
}


export function moreListitems(button, listItem, number) {
  let items = document.querySelectorAll(listItem)
  let i
  let hiddenItems = []

  function checkItemsNumber() {
    if(items.length > 4) {
      for(i=0; i < items.length; i++) {
        if(items[i].offsetHeight == 0) {
          hiddenItems.push(items[i])
        }
      }
    } else {
      return
    }
  }

  function sliceArrayItems() {
    let chosenItems = hiddenItems.slice(0,number)
    let e

    for(e=0; e < chosenItems.length; e++) {
      chosenItems[e].classList.add('visible')
    }

    if(hiddenItems.length <= 4) {
      document.querySelector('.stats__list-button').classList.add('hide')
    }
    
    hiddenItems = []
  }


  OBSERVER.add({
    name: 'loadListItems',
    event: 'click',
    target: button,
    function: checkItemsNumber
  })

  OBSERVER.add({
    name: 'loadListItems',
    event: 'click',
    target: button,
    function: sliceArrayItems
  })

  OBSERVER.on('loadListItems')

}

export function refreshCounterBox(data) {
  if (data.success){
      (data.isDouce) ? $('.button-douce').addClass('active') : $('.button-douce').removeClass('active');
      (data.isRebelle) ? $('.button-rebelle').addClass('active') : $('.button-rebelle').removeClass('active');
      $('.douceCount').html(data.douceCount);
      $('.rebelleCount').html(data.rebelleCount);
  }
}


export function playVideo() {
  let video = document.querySelector('.js-video-autoplay')
  if(video){
    video.play()
  }
}


export function backToTop() {
  let back = document.querySelector('.back-to-top')
  let footer = document.querySelector('footer')

  OBSERVER.add({
    name: 'backFixed',
    event: 'scroll',
    function: onScroll
  })

  OBSERVER.on('backFixed')

  function onScroll() {
    let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    if (window.scrollY > 300 && !isVisible(footer))
      back.classList.add('active')
    else
      back.classList.remove('active')
  }
}