// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------

// Bannière d'accueil
export const slickHomeBanner = () => {
    $('.js-home-banner-slider').slick({
        arrows: false,
        dots: true,
        appendDots: '.js-home-banner-slider-dots',
        speed: 600,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        infinite: true,
        centerMode: true,
  })
}

// Slider de la zone 4 | Accueil
export const slickFourthZone = () => {
    $('.js-fourth-zone-slider').slick({
      arrows: false,
      speed: 600,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      infinite: true,
  })
  

  $(document).find('.js-fourth-zone-slider-prev').on('click', () => {
    $(document).find('.js-fourth-zone-slider').slick('slickPrev')
  })

  $(document).find('.js-fourth-zone-slider-next').on('click', () => {
      $(document).find('.js-fourth-zone-slider').slick('slickNext')
  })
}

// Slider de la zone 4 | Accueil
export const wordsOnImageSlider = () => {
  $('.js-word-on-image-slider').slick({
    arrows: false,
    speed: 6000,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 1,
    pauseOnHover: false,
    infinite: true,
    cssEase: 'linear'
  })
  
  $(document).find('.js-fourth-zone-slider-prev').on('click', () => {
    $(document).find('.js-fourth-zone-slider').slick('slickPrev')
  })

  $(document).find('.js-fourth-zone-slider-next').on('click', () => {
      $(document).find('.js-fourth-zone-slider').slick('slickNext')
  })
}

// Slider des pages de détail
export const slickSliderZone = () => {
  $('.js-slider-zone-slider-image').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    asNavFor: '.js-slider-zone-slider-text'
  })

  $('.js-slider-zone-slider-text').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    asNavFor: '.js-slider-zone-slider-image'
  })

    $(document).find('.js-slider-zone-slider-prev').on('click', () => {
      $(document).find('.js-slider-zone-slider-image').slick('slickPrev')
    })

    $(document).find('.js-slider-zone-slider-next').on('click', () => {
        $(document).find('.js-slider-zone-slider-image').slick('slickNext')
    })
}


// ---------------------------------------------------------------------------
// Fonction permettant de détruire un carousel Slick
// ---------------------------------------------------------------------------
export function destroySlick() {
  $('.slick-initialized').slick('unslick')
}