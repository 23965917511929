// |--------------------------------------------------------------------------
// | Helper
// |--------------------------------------------------------------------------
// |
// | Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
// |--------------------------------------------------------------------------

// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import { OBSERVER } from '../main.js'

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}

// Englobe les iframe de vidéos youtube pour gérer le ratio
export function wrapYtVideo(){
  $('.dynamic iframe[src*="youtube.com"]').wrap( '<div class=\'videoWrapper\'><div class=\'ytVideo\'></div></div>' );
}


// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  let box = typeof options.element === 'string' ? document.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}


// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Détecte si un élément est visible dans le viewport et retourne une condition
export function isVisible(element) {
  const rect = element.getBoundingClientRect()

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth  || document.documentElement.clientWidth

  const top = rect.top <= viewportHeight
  const right = rect.right >= 0 - viewportWidth
  const bottom = rect.bottom >= 0 - viewportHeight
  const left = rect.left <= viewportWidth

  return top && right && bottom && left
}


// Retourne si l'utilisateur est sur mobile ou pas
export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}

export function addVideo() {
  let themePath = window.config.theme_path

  if(!isMobile()) {
    $('.home-banner__video-container').append(
      '<video class="home-banner__video" muted loop autoplay><source src="/themes/'
      + themePath + 
      '/assets/medias/videos/doucerebelle1.mp4" type="video/mp4"></video>'
      )
  } else {
    $('.home-banner__video-container').append(
      '<picture class="home-banner__placeholder-picture"><img class="home-banner__placeholder-img" data-object-fit="cover" src="/themes/'
      + themePath + 
      '/assets/medias/images/_temp/placeholder.jpg" alt="Image remplaçant la vidéo"></picture>'
    )
  }
}

// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  let vh = window.innerHeight

  const onResize = () => {
    if (width != (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)) {
      width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      vh = window.innerHeight
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  document.documentElement.style.setProperty('--vh', `${vh}px`)

  OBSERVER.add({ name: 'viewport-height', event: 'resize', function: onResize })
  OBSERVER.on('viewport-height')
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}


function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}
