// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Overlay from '../../libraries/overlay.js'
import { copyTextToClipboard } from './helper.js'


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay menu
// ---------------------------------------------------------------------------
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800, 
      closeOnResize: false,
      disabledPageScroll: true,
    }
  })
  
  menu.init()
}


export const overlayShare = () => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '[data-js="close-overlay-share"]',
      }
    },
    options: {
      speed: 600,
    }
  }).init()

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.rubric-share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Douce Rebelle | Rouyn-Noranda&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.aio-overlay-share__background, .aio-overlay-share__x').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Douce Rebelle | Rouyn-Noranda&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

}