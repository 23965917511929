// |--------------------------------------------------------------------------
// | Importation des scripts
// |--------------------------------------------------------------------------
// |
// | Main.js importe tous les fichiers qu'on a de besoin, ça commence par les
// | node-modules, puis les fichiers propres au projet.
// |--------------------------------------------------------------------------

// Initialisation sur toutes les pages
import $ from 'jquery'
window.jQuery = $
window.$ = $
import { initBarba } from './barba'
import Observer from './../libraries/observer.min.js'
import Scrollfire from './../libraries/scrollfire.min.js'
// Slick Carousel
import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
// Formulaires
import 'jquery-validation'
import 'jquery-mask-plugin'
import 'jquery-validation/dist/additional-methods.js'

// Importation du style
import '../scss/style.scss'

// Assignation de constantes
export const OBSERVER = Observer.getInstance()
export const SCROLLFIRE = Scrollfire.getInstance()

// Démarrage des scripts en passant par barba
addEventListener('DOMContentLoaded', () => {
  initBarba()
})
