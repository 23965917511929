// |--------------------------------------------------------------------------
// | Transition avec barba et gestion des vues
// |--------------------------------------------------------------------------
// |
// | C'est ici que les transitions entre les pages sont gérées, on y retrouve
// | les différentes vues spécifiques à chaque page. Les vues sont également
// | définies ici.
// |--------------------------------------------------------------------------

import barba from '@barba/core'
import { defaultTransition } from './transitions/default-transition.js'
import { textareaHeight, formContact, formSuccess, resetDropzone } from './functions/form.js'
import { addSpanToButtons, addSvgToLinks, changeBackgroundColorOnScroll, clickCount, moreListitems, readJson, clickToScrollToBlock, refreshCounterBox, playVideo, backToTop  } from './functions/functions.js'
import { addVideo, hundredVH, isMobile, removeHoverOnMobile } from './functions/helper.js'
import { slickFourthZone, slickHomeBanner,slickSliderZone, wordsOnImageSlider, destroySlick } from './functions/sliders.js'
import { overlayMenu, overlayShare } from './functions/overlays.js'
import { OBSERVER } from './main.js'
import Overlay from '../libraries/overlay.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.refreshCounterBox = refreshCounterBox

export const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    overlayMenu()
    overlayShare()
    isMobile() // Vérifie si l'usager est sur mobile ou non
    removeHoverOnMobile() // Enlève les effets d'hover en mobile
    hundredVH() // Permet de donner la hauteur exact en mobile de 100vh
    addSpanToButtons() // Ajoute des span dans les boutons
    clickToScrollToBlock({ selector: '.back-to-top' })
    backToTop()
  })
  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    destroySlick()
  })
  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })
  barba.init({

    // Options
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions
    transitions: [
      defaultTransition()
    ],

    // Views
    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          
        },
        afterLeave () {}
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          playVideo()
          // addVideo() // Permet d'ajouter une vidéo dans la bannière d'accueil
          slickHomeBanner() // Slider de la bannière d'accueil
          slickFourthZone() // Slider de la 4ème zone de l'accueil
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          formContact() //Formulaire de test, devra être changé
          textareaHeight() // Ajuste la hauteur des textearea
          wordsOnImageSlider()
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'etudierView',
        beforeEnter() {
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
          slickSliderZone() // Slider des pages de détail
          moreListitems('.stats__list-button', '.stats__list-item', 4) // Fait apparaître des éléments supplémentaires
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'travaillerView',
        beforeEnter() {
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
          slickSliderZone() // Slider des pages de détail
          moreListitems('.stats__list-button', '.stats__list-item', 4) // Fait apparaître des éléments supplémentaires
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'entreprendreView',
        beforeEnter() {
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
          slickSliderZone() // Slider des pages de détail
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'devenirAmbassadeurView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
        },
        afterLeave () {}
      },
      {
        namespace: 'visiterView',
        beforeEnter() {
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
          slickSliderZone() // Slider des pages de détail
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
      {
        namespace: 'vivreView',
        beforeEnter() {
          wordsOnImageSlider()
          changeBackgroundColorOnScroll() // Change la couleur du background
          slickSliderZone() // Slider des pages de détail
          moreListitems('.stats__list-button', '.stats__list-item', 4) // Fait apparaître des éléments supplémentaires
          clickToScrollToBlock({ selector: '.js-scroll-first-section', scrollTo: '.js-first-section' })
        },
        afterLeave () {}
      },
    ]
  })
}
